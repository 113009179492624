import TagManager from "react-gtm-module";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

const tagManagerArgs = {
  gtmId: 'GTM-PS23T3N'
};
TagManager.initialize(tagManagerArgs);

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();