import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";
import '../../assets/fonts/mobirise.svg'


class Services extends Component {
    state = {//TODO lo voy a configurar a mano en lugar de pasar estos datos
        services : [
            { title : "Microservicios", icon : "../../assets/images/features/android.png", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
            { title : "DevOps", icon : "mbri-features", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
            // { title : "Native Android", icon : "mbri-growing-chart", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
        ],
        services1 : [
            // { title : "Native iOS", icon : "mbri-photos", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
            { title : "Cloud Computing", icon : "mbri-responsive", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
            { title : "AGILE", icon : "mbri-download", description : "Scrum." },
        ],
        
    }
    render() {
        return (
            <React.Fragment>
        <section id="service" className="section">
            <Container>
            <SectionTitle title1="Mejores" title2="Servicios" />
                <Row className="pt-4 mt-4">
                <ServiceBox services={this.state.services} />
                </Row>
                {/* <Row>
                <ServiceBox services={this.state.services1} />
                </Row> */}
            </Container>
        </section>
            </React.Fragment>
        );
    }
}

export default Services;