import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../../components/common/section-title";

//Import Images
import img1 from "../../assets/images/arch-dialog/arch_diagram.png";
import img2 from "../../assets/images/mobil-app-dev.png";
import img3 from "../../assets/images/gitlab-devops.png";

import FeatureBox from "../common/feature-box";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="features">
          <Container>
            <SectionTitle title1="¿Qué" title2="hacemos?" />
            <Row className="mt-4 pt-4 vertical-content">
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={img1}
                    alt=""
                    className="mx-auto d-block"
                    height="280"
                  />
                </div>
              </Col>
              <Col lg="6" className="mt-2 text-center">
                <FeatureBox
                  class=""
                  title="Diseñamos y construimos la arquitectura de tu proyecto"
                  desc="Tanto si ya tienes un negocio o estás empezando, podemos ayudarte a crear o migrar los diferentes servicios a una arquitectura construida en la nube."
                  link="#"
                />
              </Col>
            </Row>
            <Row className="mt-5 vertical-content" id="solidsak-features">
              <Col lg="6" className="mt-2">
                <FeatureBox
                  class="text-center"
                  title="CI/CD"
                  desc="Con un proceso de integración y distribución continuo en GitLab"
                  link="#"
                />
              </Col>
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={img3}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>
            
            <Row className="mt-4 pt-4 vertical-content" id="solidsak-features">
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={img2}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6" className="mt-2 text-center">
                <FeatureBox
                 class="text-center"
                 title="Desarrollo Móvil"
                 desc="Si también necesitas una aplicación móvil, desarrollamos aplicaciones nativas para Android e iOS."
                 link="#"
                />
              </Col>
            </Row>
           
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
