import React, { Component } from 'react';
import { Col } from "reactstrap";
import android from  "../../assets/images/features/android.png";
import ios from  "../../assets/images/features/iOS.png";
import cloud from  "../../assets/images/features/cloud.jpg";
import devops from  "../../assets/images/features/devops.jpg";
import microservicios from  "../../assets/images/features/microservicios.jpg";
import agile from  "../../assets/images/features/agile.png";

class ServiceBox extends Component {
    render() {
        

      
        return (
            <React.Fragment>
                
                   
                        
                        <Col lg="4" className="mt-3">
                            
                            <div className="service-box clearfix p-4">
                                <div className="service-icon service-left"><img src={microservicios} height='60'/></div>
                                <div className="service-desc service-left">
                                    <h4>Microservicios</h4>
                                    <p className="text-muted mb-0">Creamos o migramos proyectos a una arquitectura de microservicios con servicios modulares.</p>
                                </div>
                            </div>
                        </Col>

                        {/* <Col lg="4" className="mt-3">
                            
                            <div className="service-box clearfix p-4">
                                <div className="service-icon service-left"><img src={ios} height='60'/></div>
                                <div className="service-desc service-left">
                                    <h4>Native iOS</h4>
                                    <p className="text-muted mb-0">Desarrollo de aplicaciones nativa en iOS con Swift.</p>
                                </div>
                            </div>
                        </Col> */}


                        {/* <Col lg="4" className="mt-3">
                            
                            <div className="service-box clearfix p-4">
                                <div className="service-icon service-left"><img src={android} height='60'/></div>
                                <div className="service-desc service-left">
                                    <h4>Native Android</h4>
                                    <p className="text-muted mb-0">Desarrollo de aplicaciones nativas en Android con Kotlin y Java.</p>
                                </div>
                            </div>
                        </Col> */}

                        <Col lg="4" className="mt-3">
                            
                            <div className="service-box clearfix p-4">
                                <div className="service-icon service-left"><img src={cloud} height='68'/></div>
                                <div className="service-desc service-left">
                                    <h4>Cloud</h4>
                                    <p className="text-muted mb-0">Diseño e implementación de alto nivel con arquitecturas de diferentes proveedores de computación en la nube como Azure, AWS, GCP.</p>
                                </div>
                            </div>
                        </Col>

                        <Col lg="4" className="mt-3">
                            
                            <div className="service-box clearfix p-4">
                                <div className="service-icon service-left"><img src={devops} height='40'/></div>
                                <div className="service-desc service-left">
                                    <h4>DevOps</h4>
                                    <p className="text-muted mb-0">Todo el desarrollo y puesta en producción se realiza mediante un proceso DevOps.</p>
                                </div>
                            </div>
                        </Col>
                        {/* <Col lg="4" className="mt-3">
                            
                            <div className="service-box clearfix p-4">
                                <div className="service-icon service-left"><img src={agile} height='60'/></div>
                                <div className="service-desc service-left">
                                    <h4>Agile</h4>
                                    <p className="text-muted mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </Col> */}

                    

                    
                    
                
            </React.Fragment>
        );
    }
}

export default ServiceBox;