import React, { Component } from "react";
import { Container, Row, Col, FormGroup, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Animated } from "react-animated-css";
import axios from 'axios';
import SectionTitle from "../common/section-title";
import env from "../../config";

class GetInTouch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      email: "",
      subject: "",
      comments: "",
      phone: "",
      msgSendSuccess: false,
    };
  }

  handleSubmit = async () => {
    let params = `name=${this.state.firstname}&mail=${this.state.email}&subject=${this.state.subject}&phone=${this.state.phone}&message=${this.state.comments}`

    let config = {
      method: 'post',
      url: `${env.front.api_url}/email/?${params}`,
      headers: {}
    };


    let emailPattern = new RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);
    // let phonePattern = new RegExp(/^[0-9\b]+$/);

    if (
      this.state.firstname !== "" &&
      this.state.email !== "" &&
      emailPattern.test(this.state.email) 
      // phonePattern.test(this.state.phone)
    ) {


      try {
        let res = await axios(config)

        console.log(":::> " + res);


        if (res.data.success) {
          console.log(JSON.stringify(res.data));
          this.setState({ msgSendSuccess: true });
          this.myFormRef.reset();

          //set values to empty
          this.state.firstname = ""
          this.state.email = ""
          this.state.subject = ""
          this.state.phone = ""
          this.state.firstcommentsname = ""

          setTimeout(() => {
            this.setState({ msgSendSuccess: false });
          }, 7000);
        }
      } catch (err) {
        setTimeout(() => {
          this.setState({ msgSendSuccess: false });
        }, 7000);
        console.log("error: " + err)
      }

    } else {
      console.log("ALGO FALLO");
    }
  };

  onInputChangeHandlar = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({ [name]: value });
  };

  render() {
    return (
      <React.Fragment>
        <section className="section " id="contact">
          <Container>
            <SectionTitle title1="Contáctanos" title2="sin compromiso" />
            <div className="custom-form mt-4 pt-4">
              <div id="message">
                {this.state.msgSendSuccess ? (
                  <Animated
                    animationIn="bounceIn"
                    animationOut="flash"
                    animationInDuration={1000}
                    animationOutDuration={1000}
                    isVisible={true}
                  >
                    <fieldset>
                      <div id="success_page">
                        <h3>Correo enviado correctamente.</h3>
                        <p>
                          Gracias <strong>{this.state.firstname}</strong>,
                          nos pondremos en contacto con usted lo antes posible.
                        </p>
                      </div>
                    </fieldset>
                  </Animated>
                ) : null}
              </div>
              <AvForm
                name="contact-form"
                id="contact-form"
                ref={(el) => (this.myFormRef = el)}
                onSubmit={(e) => this.handleSubmit(e)}
              >
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label for="name">Nombre</Label>
                      <AvField
                        name="firstname"
                        placeholder="Tu nombre..."
                        type="text"
                        errorMessage="Este campo es necesario"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        onChange={(e) => this.onInputChangeHandlar(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="email">Correo electrónico</Label>
                      <AvField
                        name="email"
                        placeholder="Tu correo..."
                        type="email"
                        errorMessage="Introduce un correo válido"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          email: { value: true },
                        }}
                        onChange={(e) => this.onInputChangeHandlar(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="subject">Empresa</Label>
                      <AvField
                        name="subject"
                        placeholder="Nombre de tu empresa..."
                        type="text"
                        errorMessage=""
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom01"
                        onChange={(e) => this.onInputChangeHandlar(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="phone">Teléfono</Label>
                      <AvField
                        name="phone"
                        placeholder="Tu número de teléfono..."
                        type="number"
                        errorMessage="Este campo es necesario"
                        className="form-control"
                        validate={{ required: { value: false } }}
                        id="validationCustom01"
                        onChange={(e) => this.onInputChangeHandlar(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label for="comments">¿En que podemos ayudarte?</Label>
                      <AvField
                        type="textarea"
                        name="comments"
                        id="comments"
                        rows="4"
                        className="form-control"
                        errorMessage=""
                        placeholder="Quiero..."
                        // validate={{ required: { value: true } }}
                        onChange={(e) => this.onInputChangeHandlar(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" className="text-right">
                    <input
                      type="submit"
                      id="submit"
                      name="send"
                      className="submitBnt btn btn-primary btn-custom"
                      value="enviar mensaje"
                    />
                    <div id="simple-msg"></div>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default GetInTouch;
